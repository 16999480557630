import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="attachment"
export default class extends Controller {
  connect() {
    var file_id = document.getElementById('file-id');
    var indicator = document.getElementById('indicator');
    var upload_btn = document.getElementById('upload-btn');
    var upload_div = document.getElementById('upload-div');
    var upload_close = document.getElementById('upload-close');
    var content = document.getElementById('upload-content');
    var csv_submit = document.getElementById('csv-submit');

    file_id.addEventListener('change', submitForm);
    upload_btn.addEventListener('click', displayToggle);
    upload_close.addEventListener('click', displayToggle);
    csv_submit.addEventListener('click', () => {
      document.getElementById('form-id').submit();
    })

    function displayToggle() {
      if(upload_div.classList.contains('show')){
        upload_div.classList.remove('show');
        upload_div.classList.add('hidden');
      } else {
        upload_div.classList.remove('hidden');
        upload_div.classList.add('show');
      }
    }

    function submitForm() {
      content.innerHTML = file_id.value.replace(/C:\\fakepath\\/i, '');
      indicator.classList.add('ripple');
      csv_submit.classList.add('csv-submit');
      csv_submit.innerHTML = "SUBMIT";
      //document.getElementById('form-id').submit();
    }
  }
}
