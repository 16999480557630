import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="sign-up-dropdown"
export default class extends Controller {
  connect() {
    var dept = document.getElementById("dept_dropdown");
    var team = document.getElementById("team_dropdown");
    var pos = document.getElementById("pos_dropdown");

    dept.addEventListener("change", function () {
      if (document.querySelector("#dept_dropdown").children[1].value == 3 && (document.querySelector("#pos_dropdown").children[1].value == 1 || document.querySelector("#pos_dropdown").children[1].value == 2 || document.querySelector("#pos_dropdown").children[1].value == 3)) {
        document.querySelector("#team_dropdown").style.display = "block";
      } else {
        document.querySelector("#team_dropdown").style.display = "none";
      }
    });

    pos.addEventListener("change", function () {
      if (document.querySelector("#dept_dropdown").children[1].value == 3 && (document.querySelector("#pos_dropdown").children[1].value == 1 || document.querySelector("#pos_dropdown").children[1].value == 2 || document.querySelector("#pos_dropdown").children[1].value == 3)) {
        document.querySelector("#team_dropdown").style.display = "block";
      } else {
        document.querySelector("#team_dropdown").style.display = "none";
      }
    });
  }
}
