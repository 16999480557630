import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="sidenav-toggler"
export default class extends Controller {
  connect() {
    var toggler = document.getElementById("sidenav-toggler");
    var sidenav = toggler.parentNode;
    var is_shown = localStorage.getItem("sidenav_is_shown");

    if (is_shown == "false") {
      hide();
    } else {
      show();
    }

    toggler.addEventListener("click", function () {
      if (sidenav.classList.contains("show")) {
        hide();
      } else {
        show();
      }
    });

    function hide() {
      if (sidenav.classList.contains("show")) {
        sidenav.classList.remove("show");
      }

      sidenav.classList.add("hidden");
      localStorage.setItem("sidenav_is_shown", false);
    }

    function show() {
      if (sidenav.classList.contains("hidden")) {
        sidenav.classList.remove("hidden");
      }
      sidenav.classList.add("show");
      localStorage.setItem("sidenav_is_shown", true);
    }
  }
}
