// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
import * as Turbo from '@hotwired/turbo'
Turbo.start()
//
import ActiveStorage from '@rails/activestorage'
ActiveStorage.start()

import "@hotwired/stimulus"
import '../controllers'
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')
//const requireModule = require.context('./packs', true, /\.js$/)
//requireModule.keys().forEach(fileName => requireModule(fileName))

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

//Packs entrypoint
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import "../channels"
import "@hotwired/turbo-rails"


Rails.start()
Turbolinks.start()