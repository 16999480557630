import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="text-input"
export default class extends Controller {
  // static targets = ["output", "input"];
  // connect() {
  // changed() {
  //   this.outputTarget.textContent = this.inputTarget.children[0].value;
  //   console.log(this.outputTarget);
  //   console.log(this.inputTarget);
  // }
}
