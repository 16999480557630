import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="employees-monthly-view"
export default class extends Controller {
  connect() {
    // document.addEventListener("DOMContentLoaded", function () {
    //   var submitButtons = document.querySelectorAll("[type='submit']");
    //   submitButtons.forEach((button) => {
    //     button.addEventListener("click", function (event) {
    //       alert(1);
    //       var reloadPage = event.target.getAttribute("data-reload-page");
    //       if (reloadPage === "true") {
    //         window.location.reload();
    //       }
    //     });
    //   });
    // });
  }
}
