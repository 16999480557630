import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="spinner"
export default class extends Controller {
  connect() {
    var link_tos = document.getElementsByClassName("spinner-click");
    var reload_page_click = document.getElementsByClassName("reload-page");

    for (let i = 0; i < link_tos.length; i++) {
      link_tos[i].addEventListener("click", function () {
        link_tos[i].classList.add("spinner-show");
      });
    }

    for (let i = 0; i < reload_page_click.length; i++) {
      reload_page_click[i].addEventListener("click", function () {
        location.reload();
      });
    }
  }
}
