import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="toasts"
export default class extends Controller {
  connect() {
    var toasts = document.getElementsByClassName("toast-container");
    const timeOut = setTimeout(function () {
      if (toasts.length > 0) {
        for (let i = 0; i < toasts.length; i++) {
          toasts[i].style.display = "none";
        }
      }
    }, 5000);
  }
}
