import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="sidenav-menu"
export default class extends Controller {
  connect() {
    let sidebar = document.querySelector('.sidebar')
    let closeBtn = document.querySelector('#btn')

    closeBtn.addEventListener('click', () => {
      sidebar.classList.toggle('open')
      menuBtnChange()
    })

    function menuBtnChange() {
      if (sidebar.classList.contains('open')) {
        closeBtn.classList.replace('bx-menu', 'bx-menu-alt-right')
      } else {
        closeBtn.classList.replace('bx-menu-alt-right', 'bx-menu')
      }
    }
  }
}
